type Props = {};
import Logo from "./assets/images/logo.svg";
import Cafe from "./assets/images/cafe5.jpg";

const App = (props: Props) => {
  return (
    <div className="h-screen w-scree relative">
      <div
        className="absolute top-0 left-0 h-full w-full"
        style={{
          backgroundImage: `url(${Cafe})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(2px)",
          zIndex: -1,
        }}
      ></div>
      <div className="h-[20%] flex justify-start">
        <div
          className="bg-red-300 h-40 w-40 m-2 shadow-lg drop-shadow-lg"
          style={{ borderRadius: "50%" }}
        >
          <img src={Logo} alt="" style={{ zIndex: 1, borderRadius: "50%" }} />
        </div>
      </div>
      <div className="h-[80%] flex justify-center items-center text-3xl font-semibold">
        <div className="bg-white text-black p-2 rounded-md shadow-md">
          Work In Progress ...
        </div>
      </div>
    </div>
  );
};

export default App;
