import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { USER_LOGGED_IN, USER_LOGGED_OUT } from "./store/HomeSlice";
import { useNavigate } from "react-router-dom";

type Props = {};

function Home({}: Props) {
  return <div>Home</div>;
}

export default Home;
